<script setup>
import {Head, Link, useForm} from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Auth/Checkbox.vue';
import InputError from '@/Components/Auth/InputError.vue';
import PrimaryButton from '@/Components/Auth/PrimaryButton.vue';
import TextInput from '@/Components/Auth/TextInput.vue';
import InputLabel from "@/Components/Auth/InputLabel.vue";
import {computed} from "vue";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});
const emailErrors = computed(() => !form.errors.email === undefined)
const passwordErrors = computed(() => !form.errors.password === undefined)
const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>
<script>
import AuthLayout from "@/Layouts/AuthLayout.vue";

export default {
    layout: AuthLayout,
}
</script>

<template>
    <Head title="Log in"/>
    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo/>
        </template>
        <template #headerTitle>Welcome Back</template>
        <template #headerSubtitle>Please log in to continue</template>
        <div v-if="status" class="mb-4 font-medium text-sm text-green-600 dark:text-green-400 text-center">
            {{ status }}
        </div>
        <form @submit.prevent="submit">
            <label class="block">
                <InputLabel for="email" value="Email"/>
                <TextInput
                    id="email"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter Email"
                    required
                    autofocus
                    :has-errors="emailErrors"
                    autocomplete="username"
                >
                    <template #icon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 transition-colors duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                        </svg>
                    </template>
                </TextInput>
                <InputError class="mt-2" :message="form.errors.email"/>
            </label>
            <label class="mt-4 block">
                <InputLabel for="password" value="Password"/>
                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    placeholder="Enter Password"
                    required
                    :has-errors="passwordErrors"
                    autocomplete="current-password"
                >
                    <template #icon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5 transition-colors duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                        </svg>
                    </template>
                </TextInput>
                <InputError class="mt-2" :message="form.errors.password"/>
            </label>
            <div class="mt-4 flex items-center justify-between space-x-2">
                <label class="inline-flex items-center space-x-2">
                    <Checkbox v-model:checker="form.remember" name="remember"/>
                    <span class="line-clamp-1">Remember me</span>
                </label>
                <Link v-if="canResetPassword"
                      :href="route('password.request')"
                      class="text-xs text-dark-text transition-colors line-clamp-1 hover:text-dark-text focus:text-dark-text dark:text-light-text dark:hover:text-lighter-text dark:focus:text-lighter-text">
                    Forgot Password?
                </Link>
            </div>
            <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                Log in
            </PrimaryButton>
        </form>
        <div class="mt-4 text-center text-xs+">
            <p class="line-clamp-1">
                <span>Dont have an account?</span>
                <Link
                    class="text-primary-light transition-colors hover:text-primary-light/70 dark:text-primary-dark dark:hover:text-primary-dark/80"
                    :href="route('register')">
                    Create account
                </Link>
            </p>
        </div>
    </AuthenticationCard>
</template>
